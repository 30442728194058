main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
  border-color: rgba(0, 0, 0, 0.15);
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration-style: dotted;
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
  margin: 0 10px 0 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

img {
  border-style: none;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

video,
iframe {
  max-width: 100%;
  height: auto;
}

a {
  background-color: transparent;
  color: #f8a61f;
  transition-duration: 0.2s;
  transition-property: opacity, color, background-color;
}

a:hover {
  text-decoration: none;
}

.a-link {
  color: rgb(153, 111, 43);
  text-decoration: none;
}

.a-link:hover {
  color: rgb(214, 148, 41);
}

/* убираем выделение ссылок пунктирной рамкой в FF и IE */
a:active,
a:focus {
  outline: 0;
}

::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

::-moz-placeholder {
  color: #999999;
  opacity: 1;
}

/* Firefox 19+ */
:-moz-placeholder {
  color: #999999;
  opacity: 1;
}

/* Firefox 18- */
:-ms-input-placeholder {
  color: #999999;
  opacity: 1;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
}

:focus:-moz-placeholder {
  color: transparent;
}

:focus:-ms-input-placeholder {
  color: transparent;
}

/* color: #858585; */
[type="text"],
[type="email"],
[type="tel"],
[type="url"],
[type="password"],
[type="number"],
[type="search"],
[type="button"],
[type="color"],
[type="time"],
[type="date"],
[type="datetime-local"],
[type="file"],
[type="image"],
[type="month"],
[type="reset"],
[type="week"],
select,
textarea,
.field__container {
  width: 100%;
  height: 45px;
  background-color: white;
  border: 0;
  border-radius: 0;
  padding: 0 10px;
  box-sizing: border-box;
  transition: none;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  outline: none;
  min-width: 40px;
}

textarea {
  resize: none;
  text-align: left;
  padding: 5px 10px;
  line-height: calc(14px + 2px);
}

textarea:focus {
  box-shadow: none;
}

[type="text"]:focus,
[type="email"]:focus,
[type="tel"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="search"]:focus,
[type="button"]:focus,
[type="color"]:focus,
[type="time"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="file"]:focus,
[type="image"]:focus,
[type="month"]:focus,
[type="reset"]:focus,
[type="week"]:focus {
  box-shadow: none;
  border: 0;
  outline: none;
}

option {
  background-color: #fff;
  color: #858585;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

option:hover {
  color: #000;
  background-color: #496c00;
}

/* Оформление checkbox */
.checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.checkbox__label {
  height: 18px;
  display: inline-flex;
  align-items: center;
  /*   padding-right: 10px; */
}

.checkbox__name {
  margin-right: 10px;
}

.checkbox__block {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.checkbox__block:after {
  font-family: "gwi", serif;
  content: "\e806";
  position: absolute;
  top: 0;
  left: 3px;
  opacity: 0;
  transition: 0.2s all;
  pointer-events: none;
  font-size: 11px;
  color: #398fcd;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked + .checkbox__block:after {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}

/* field */
.field__container {
  display: flex;
  align-items: center;
}

.field {
  position: relative;
  width: 100%;
}

.field_grp {
  display: flex;
  background-color: white;
  border: 1px solid #cacaca;
  border-radius: 5px;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.field_mb {
  margin-bottom: 12px;
}

/*color: #585757;*/
.field__desc {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  width: 130px;
  flex-shrink: 0;
  background-color: #e9ecef;
  border-right: 1px solid #cacaca;
}

.field__ico {
  width: 30px;
  height: 30px;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 6px 5px 5px;
}

.field__box {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
}

.field__box_prl {
  padding: 0 10px;
}

.field__box_br {
  border-right: 1px solid #cacaca;
}

.field__box_bl {
  border-left: 1px solid #cacaca;
}

.field__box_color_grey {
  background-color: #e9ecef;
}

.field__box_color_green {
  background-color: #2eff00;
}

.field__tool {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  flex-shrink: 0;
}

.prefix_field_tool {
  justify-content: start;
  align-items: start;
  width: 24px !important;
}

input[name="prefix"] {
  padding: 0 4px !important;
  min-width: 42px !important;
  text-transform: uppercase;
}

.field__tool-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.inp-error {
  color: rgb(255, 84, 18);
  margin-bottom: 5px;
}

.inp-error ~ input {
  outline: 1px solid rgb(255, 84, 18);
}

.inp_login_pl {
  padding-left: 80px;
}

.protection {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.protection_mb {
  overflow: hidden;
  margin-bottom: 12px;
}

.protection__captcha {
  max-width: 306px;
}

.agreement {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement_mb {
  margin-bottom: 12px;
}

.agreement__box {
  display: flex;
  justify-content: center;
  background-color: #e9ecef;
  border: 1px solid #cacaca;
  color: #565656;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 5px;
}

.agreement__content {
  padding: 4px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.agreement__link {
  text-decoration: none;
  color: #565656;
}

.agreement__link:hover {
  color: #b6862d;
}

.agreement__check {
  width: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement__check_left {
  border-right: 1px solid #cacaca;
}

.agreement__check_right {
  border-right: 1px solid #cacaca;
}

.form__btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

/* prefix */
.field__prefix {
  min-width: 35px;
  text-align: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prefix {
  position: relative;
  z-index: 5;
  cursor: pointer;
}

.prefix__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  position: relative;
  z-index: 0;
}

.prefix__list {
  background-color: #fff;
  color: rgb(133, 133, 133);
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  position: absolute;
  left: 0;
  top: calc(100% - 50px);
  width: 80px;
  transition: 0.3s all;
  box-sizing: border-box;
  border: 1px solid #232323;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  text-align: left;
}

.prefix__active ~ .prefix__list {
  top: calc(100% + 3px);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.prefix__check {
  line-height: 30px;
  transition: 0.3s all;
  padding-left: 5px;
}

.prefix__check:hover {
  background-color: #d8d8d8;
}

.prefix__input:not([type="checkbox"]):not([type="radio"]) {
  background-color: #fff;
  height: 30px;
  min-height: 30px;
  border-top: 1px solid #232323;
  padding: 5px;
  text-transform: none;
}

/* main */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: "Alegreya Sans SC", "PT Sans", Tahoma, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  cursor: default;
}

body {
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  background-color: #110f12;
}

.page {
  position: relative;
  z-index: 0;
  background: #110f12 url(../images/bg/bg.webp) no-repeat center top;
  /*background: #110f12 url(../images/bg/bg-mobile.jpg) no-repeat center top;*/
  min-height: 100vh;
  min-width: 470px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  transition: 0.3s all;
}

.modal-active .page {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.section {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.box {
  max-width: 1920px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.container {
  max-width: 1090px;
  /*min-width: 470px;*/
  width: 100%;
  padding-right: calc(10px * 2);
  padding-left: calc(10px * 2);
  position: relative;
  z-index: 2;
  box-sizing: border-box;
}

.rw {
  margin-left: -10px;
  margin-right: -10px;
}

.cl {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.spacer {
  flex-grow: 1;
  min-height: 6.5rem;
}

.bar {
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(calc(-50% - 3px));
  transform: translateY(calc(-50% - 3px));
  z-index: 10;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;
}

.logotype {
  position: absolute;
  left: 40px;
  top: 50%;
  -webkit-transform: translateY(calc(-50% + 1px));
  transform: translateY(calc(-50% + 1px));
  z-index: 10;
}

.logotype_small {
  max-width: 130px;
}

.banners {
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(calc(-50% + 3px));
  transform: translateY(calc(-50% + 3px));
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 300px;
}

/* title */
.title {
  color: #fff;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3rem;
  margin: 0;
}

/* sub-title */
.sub-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0;
}

/* desc */
.desc {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

/* heading */
.heading {
  text-align: center;
  box-sizing: border-box;
  padding: 10px 0;
}

.heading__title {
}

.heading__desc {
}

/* Фиксируем позицию body, которую меняет панель гугла*/
.body_fix {
  top: 0 !important;
  position: static !important;
}

/* Прячем панель гугла */
.skiptranslate {
  display: none !important;
}

/* hint */
.hint {
  position: relative;
  z-index: 0;
  -webkit-perspective: 700px;
  perspective: 700px;
}

.hint__btn {
  background: url(../images/hint__btn.png) no-repeat center center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  cursor: default;
  position: relative;
  z-index: 5;
  transition: 0.3s all;
}

.hint__btn_cursor_pointer {
  cursor: pointer;
}

.hint__btn_cursor_pointer:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.hint__btn_js_toggle {
  cursor: pointer;
}

.hint__btn_js_toggle:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.hint__arrow {
  content: "";
  display: block;
  width: 11px;
  height: 17px;
  position: absolute;
  top: 9px;
  left: 10px;
  transition: 0.3s all;
  cursor: pointer;
  z-index: 20;
}

.hint__arrow:after {
  display: block;
  content: "";
  background: url(../images/arrow_down_normal.png) no-repeat center bottom;
  width: 26px;
  height: 30px;
  position: absolute;
  top: -6px;
  left: -7px;
}

.hint__list {
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translateX(-50%) rotateX(-90deg);
  transform: translateX(-50%) rotateX(-90deg);
  width: 164px;
  box-sizing: border-box;
  background-color: rgba(3, 3, 4, 0.8);
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  padding-top: 3px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s all;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.hint__list:before {
  display: block;
  content: "";
  background: url(../images/hint__decor_size_normal.png) no-repeat;
  width: 167px;
  height: 32px;
  position: absolute;
  top: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}

.hint__item {
  text-decoration: none;
  display: block;
  color: #b7b07d;
  font-size: 15px;
  font-weight: 900;
  line-height: 15px;
  text-transform: uppercase;
  /* padding: 7px 4px; */
  padding: 9px 4px 7px 4px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  z-index: 3;
}

.hint__item_rl {
  border-top-left-radius: 26px;
}

.hint__item_rr {
  border-top-right-radius: 26px;
}

.hint__item:hover {
  background-color: rgba(61, 57, 53, 0.3);
  color: #fff;
}

.hint__item:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0;
  border-bottom: 1px solid rgba(60, 57, 46, 0.5);
  border-top: 1px solid rgba(32, 31, 26, 0.5);
}

.hint__content {
  width: calc(100% - 18px);
  margin: 0 9px;
}

/* hint_size_large */
.hint_size_large .hint__list {
  width: 230px;
}

.hint_size_large .hint__list:before {
  display: block;
  content: "";
  background: url(../images/hint__decor_size_large.png) no-repeat;
  width: 234px;
}

/* hint_size_large */
.hint_size_small .hint__list {
  width: 118px;
}

.hint_size_small .hint__list:before {
  display: block;
  content: "";
  background: url(../images/hint__decor_size_smal.png) no-repeat;
  width: 120px;
}

/* hint__btn_active */
.hint__btn_active .hint__arrow {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.hint__btn_active + .hint__list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* hint-hover */
.hint-hover:hover .hint__arrow {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.hint-hover:hover .hint__list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* arrows */
.arrows {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows__arrow,
.arrow {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  color: #fff;
  outline: 0;
  border: 0;
  background: transparent;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  font-size: 12px;
}

.arrows__arrow {
  opacity: 1;
}

.arrows__arrow:hover {
  opacity: 0.7;
}

.arrows__arrow.swiper-button-disabled,
.arrow.swiper-button-disabled {
  opacity: 0.2;
  box-shadow: none;
  cursor: default;
}

.arrows__arrow:before,
.arrow:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1px;
  z-index: -1;
}

.arrows__arrow_prev,
.arrow_prev {
  padding-right: 2px;
}

.arrows__arrow_next,
.arrow_next {
  padding-left: 2px;
}

.slider-prev {
  background: url(../images/slider-prev.png) no-repeat center center;
  width: 23px;
  height: 27px;
}

.slider-next {
  background: url(../images/slider-next.png) no-repeat center center;
  width: 23px;
  height: 27px;
}

/* share */
.share {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share__item {
  text-decoration: none;
  display: inline-block;
  transition: 0.3s all;
  margin: 1px;
}

.share__item:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.share__item img {
  display: block;
}

/* item */
.item {
  display: flex;
  min-height: 60px;
  text-decoration: none;
  color: white;
  font-family: "Segoe UI", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  position: relative;
  padding: 0 10px;
  z-index: 0;
}

.item:after {
  display: block;
  content: "";
  background-color: rgba(37, 40, 38, 0.9);
  position: absolute;
  top: 30%;
  right: 0;
  bottom: 30%;
  left: 0;
  z-index: -1;
  transition: 0.3s all;
  opacity: 0;
}

.item:hover:after {
  opacity: 1;
  top: 0;
  bottom: 0;
}

.item__date {
  flex-shrink: 0;
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgrey;
  font-family: "Segoe UI", sans-serif;
  font-size: 11px;
  font-weight: 400;
  padding-top: 1px;
  box-sizing: border-box;
  margin-right: 8px;
}

.item__content {
  min-width: 0;
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  box-sizing: border-box;
}

.item__btns {
  width: 88px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.item:hover .item__btns {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* items */
.items {
}

.items__list_short {
  max-height: 180px;
  overflow: hidden;
}

.boxshadow {
  -webkit-filter: drop-shadow(1px 4px 0 rgba(0, 0, 0, 0.3));
  filter: drop-shadow(1px 4px 0 rgba(0, 0, 0, 0.3));
}

/* mid */
.mid {
  display: flex;
}

.mid__slider,
.mid__content {
  width: calc(100% / 2 - 3px);
}

.mid__slider {
  margin-right: 3px;
}

.mid__content {
  margin-left: 3px;
}

/* ps */
.ps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ps__img {
  display: block;
}

/* preload */
.preload {
  background-color: #110f12;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s cubic-bezier(0.64, 0.14, 0.34, 1.32);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}

.preload__logo {
  max-width: 100%;
  width: auto;
  height: auto;
  display: block;
  z-index: 2001;
}

.preload__fade {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@-webkit-keyframes preAnimateOut {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
}

@keyframes preAnimateOut {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
}

.preload__progress {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: rgb(200, 129, 107);
}

/* nav */
.nav {
  position: relative;
}

.nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__link {
  box-sizing: border-box;
  padding: 3px 10px;
  margin: 0 5px;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__ico {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: 0.3s all;
  flex-shrink: 0;
  position: relative;
}

.nav__img {
  max-width: 64px;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-filter: brightness(105%);
  filter: brightness(105%);
}

.nav__name {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.36);
  color: white;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s all;
  font-weight: 800;
}

.nav__desc {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.36);
  color: #fcd601;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
  transition: 0.3s all;
}

.nav__link:hover .nav__name {
  color: #ffc923;
}

.nav__link:hover .nav__desc {
  color: #9b7f2d;
}

/* Кнопка меню */
.gw-burger {
  position: relative;
  z-index: 5;
  background-color: transparent;
  height: 36px;
  width: 40px;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  display: none;
}

.gw-burger:after {
  display: block;
  content: "";
  background: url(../images/navigation/gw-burger-bg.png) no-repeat;
  width: 60px;
  height: 58px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.gw-burger__open {
  background: url(../images/navigation/gw-burger-open.png) no-repeat;
  width: 45px;
  height: 43px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gw-burger__close {
  background: url(../images/navigation/gw-burger-close.png) no-repeat;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.mob-nav-active .gw-burger__open {
  opacity: 0;
}

.mob-nav-active .gw-burger__close {
  opacity: 1;
}

/* navigation */
[data-section="navigation"] {
  background: #100f0d url(../images/navigation/bg.webp) no-repeat center top;
  position: relative;
  z-index: 15;
}

.navigation {
  height: 61px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
}

.navigation:after {
  content: "";
  display: block;
  width: 100%;
  max-width: 1051px;
  height: 28px;
  transition: 0.3s all;
  background: url(../images/navigation/decor.png) no-repeat center center;
  position: absolute;
  top: calc(100% - 12px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 20;
}

.navigation__nav {
  box-sizing: border-box;
  padding-right: 27px;
}

/* lang */
.lang {
  width: 32px;
  height: 32px;
  position: relative;
}

.lang:before {
  content: "";
  display: block;
  width: 44px;
  height: 47px;
  position: absolute;
  top: -4px;
  left: -6px;
  z-index: 10;
  transition: 0.3s all;
  background: url(../images/lang/lang__framing.png) no-repeat;
  pointer-events: none;
}

.lang:after {
  content: "";
  display: block;
  background: url(../images/arrow_down_small.png) no-repeat center bottom;
  width: 14px;
  height: 15px;
  position: absolute;
  left: 9px;
  top: 27px;
  transition: 0.3s all;
  cursor: pointer;
  z-index: 20;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.lang:hover:after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.lang:hover .hint__list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.lang__list {
  padding-top: 5px;
  box-sizing: border-box;
}

.lang__item {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: left;
  box-sizing: border-box;
  padding: 6px 10px;
}

.lang__item:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0;
  border-bottom: 1px solid rgba(60, 57, 46, 0.5);
  border-top: 1px solid rgba(32, 31, 26, 0.5);
}

.lang__img {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.lang__link {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s all;
}

.lang__link_sub {
  margin: 5px 0;
}

.lang__link_sub:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.lang__hint .hint__list {
  width: 320px;
  left: -80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.lang__hint .hint__list:before {
  display: block;
  content: "";
  background: url(../images/lang/lang__hint.png) no-repeat;
  width: 322px;
  height: 32px;
}

/* Убираем подсветку ссылок */
.goog-text-highlight {
  background-color: inherit !important;
  box-shadow: none;
  box-sizing: inherit !important;
}

/* start */
.start {
  position: relative;
  z-index: 5;
}

.start__btn {
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  width: 120px;
  height: 132px;
  position: relative;
  z-index: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
  color: white;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  transition: 0.3s all;
  box-sizing: border-box;
  padding-bottom: 13px;
  margin: 20px 10px 0 10px;
}

.start__btn:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.start__btn:after {
  display: block;
  content: "";
  background: url(../images/btns/start.webp) no-repeat;
  width: 164px;
  height: 185px;
  pointer-events: none;
  position: absolute;
  left: -22px;
  top: -34px;
  z-index: -1;
}

.start__hint {
  position: absolute;
  top: calc(100% - 35px);
  left: calc(50% - 17px);
  z-index: 10;
}

/* fbtn */
.fbtn {
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all;
}

.fbtn:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.fbtn:before {
  content: "";
  display: block;
  width: 44px;
  height: 47px;
  position: absolute;
  top: -4px;
  left: -6px;
  z-index: -1;
  transition: 0.3s all;
  background: url(../images/btns/fbtn.png) no-repeat;
  pointer-events: none;
}

.fbtn__pic {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

/* btn */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: 0.2s all;
  width: 140px;
  height: 32px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.22);
  color: white;
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  border: 0;
  outline: 0;
  flex-shrink: 0;
  box-shadow: 0 0 15px #466018;
  font-family: "Alegreya Sans SC", "PT Sans", Tahoma, Arial, sans-serif;
}

.btn:hover {
  box-shadow: 0 0 20px #466018;
}

.btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: 0.2s all;
  background: transparent url(../images/btns/btn.jpg) no-repeat center center;
  pointer-events: none;
}

.btn:hover:after {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.btn__content {
  position: relative;
  z-index: 5;
  display: inline-block;
}

.btn__ico {
  display: inline-block;
  position: relative;
  z-index: 5;
  flex-shrink: 0;
}

.btn__ico_m_l {
  margin-left: 5px;
}

.btn__ico_m_r {
  margin-right: 5px;
}

/* btn_size_small */
.btn_size_small {
  width: 76px;
  height: 24px;
  color: white;
  font-family: "Alegreya Sans SC", "PT Sans", Tahoma, Arial, sans-serif;
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
}

.btn_size_small:after {
  background: transparent url(../images/btns/btn_size_small.jpg) no-repeat
    center center;
}

/* btn_size_large */
.btn_size_large {
  width: 517px;
  height: 52px;
  color: white;
  font-size: 16px;
  font-weight: 900;
  font-style: italic;
  box-shadow: none;
}

.btn_size_large:hover {
  box-shadow: none;
}

.btn_size_large:after {
  background: transparent url(../images/btns/btn_size_large.webp) no-repeat
    center center;
}

/* button */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: 0.2s all;
  width: 252px;
  height: 50px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
  color: white;
  font-size: 17px;
  font-weight: 900;
  text-transform: uppercase;
  font-family: "Alegreya Sans SC", "PT Sans", Tahoma, Arial, sans-serif;
  position: relative;
  z-index: 0;
  border: 0;
  outline: 0;
  flex-shrink: 0;
}

.button:hover {
  /* box-shadow: 0 0 20px #466018; */
}

.button:after {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: -5px;
  transition: 0.2s all;
  background: transparent url(../images/btns/button.webp) no-repeat center
    center;
  pointer-events: none;
  width: 262px;
  height: 58px;
  z-index: -1;
}

.button:hover:after {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.button__content {
  position: relative;
  z-index: 5;
  display: inline-block;
}

/* logo */
.logo {
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 0;
  line-height: 0;
  transition: 0.3s all;
}

.logo:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

/* server */
.server {
  width: 154px;
  height: 154px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  position: relative;
  /*z-index: 111;*/
  border-radius: 40%;
  /*order: 1;*/
}

.server:before {
  content: "";
  display: block;
  background: url(../images/servers/server__bg_style_green.webp) no-repeat;
  width: 220px;
  height: 208px;
  position: absolute;
  top: -30px;
  left: -33px;
  transition: 5s all;
  z-index: -1;
  pointer-events: none;
}

.server__progress {
  width: 120px;
  height: 121px;
  position: absolute;
  left: 17px;
  top: 17px;
}

.server_status_off .server__progress {
  background: url(../images/servers/server__load_progress_off.webp) no-repeat
    center center;
}

.server__link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.server__hint {
  position: absolute;
  bottom: 3px;
  left: 60px;
  z-index: 20;
}

.server__status {
  width: 31px;
  height: 30px;
  position: absolute;
  left: 61px;
  top: 7px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-right: 1px;
}

.server__status:before {
  content: "";
  display: block;
  width: 33px;
  height: 33px;
  position: absolute;
  left: -1px;
  top: -2px;
  z-index: -1;
  transition: 0.3s all;
  background: url(../images/servers/server__bg_style_green.webp) no-repeat -80px
    top;
  border-radius: 50%;
}

.server__state_online {
  display: none;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
}

.server__state_online_off {
  text-shadow: 0 0 10px rgba(255, 6, 6, 0.99), 0 0 10px rgba(255, 6, 6, 0.7);
  color: red;
  display: block;
}

.server__state_online_on {
  text-shadow: 0 0 10px rgba(216, 255, 0, 0.99);
  color: #deff00;
}

.server_status_on .server__state_online_on {
  display: block;
}

.server_status_on .server__state_online_off {
  display: none;
}

.server__content {
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  position: relative;
  z-index: 5;
  width: 70px;
  height: 70px;
}

.server__front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
}

.server__title {
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  color: #a7a18a;
  line-height: 12px;
  white-space: nowrap;
}

.server__name {
  color: #ffd800;
}

.server__back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  transition: 0.3s all;
}

.server__online {
  color: white;
  font-size: 25px;
  line-height: 25px;
  font-weight: 900;
  text-transform: uppercase;
}

/* server:hover  */
.server:hover .server__back {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.server:hover .server__front {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

/* servers */
[data-section="servers"] {
  position: relative;
  z-index: 5;
  flex-grow: 1;
  align-items: center;
  max-height: 200px;
  transition: 0.3s all;
  margin: 10px 0 5px 0;
}

/* mid - slider/tabs */
[data-section="mid"] {
  margin: 5px 0 5px 0;
}

.servers {
  height: 100%;
  max-height: 200px;
  min-height: 164px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.servers__btn-box {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2 !important;
}

/* life */
.life__title {
  color: #9d9894;
  font-size: 11px;
  font-weight: 400;
  white-space: nowrap;
  text-transform: uppercase;
}

.life__title_new {
  color: cyan; /* #00ff0c */
  font-size: 0.75em;
  font-weight: 900;
  white-space: nowrap;
  text-transform: uppercase;
}

.life__days {
  color: white;
  font-size: 15px;
  line-height: 15px;
  font-weight: 900;
}

.life__days_name {
}

.life__time {
  color: #efff6b;
  font-size: 15px;
  font-weight: 900;
  text-transform: uppercase;
}

/* snews */
.snews {
  position: relative;
  z-index: 0;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.snews__slider {
  position: relative;
  z-index: 0;
}

.snews__slide {
  box-sizing: border-box;
  padding: 40px 15px 30px 15px;
  min-height: 277px;
  max-height: 277px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.snews__inner {
  text-align: center;
}

.snews__title {
  font-size: 46px;
  font-weight: 900;
  font-style: italic;
  color: white;
  text-transform: uppercase;
  margin: 0;
  text-shadow: 0 3px 4px rgba(0, 0, 0, 0.8);
}

.snews__sub-title {
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-shadow: 0 3px 4px rgba(0, 0, 0, 0.8);
}

.snews__content {
  min-height: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.snews__btn {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 900;
  font-style: italic;
  padding-top: 4px;
}

.snews__share {
  margin-top: 12px;
}

.snews__counter {
  width: 40px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
}

.snews__arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 6px;
  top: 13px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

[data-slider-pagination="snews"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  z-index: 10;
  bottom: auto !important;
}

.pag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pag__bullet {
  width: 22px;
  height: 3px;
  background-color: #6f6f6f;
  margin: 0 2px;
  cursor: pointer;
  transition: 0.3s all;
}

.pag__bullet:hover {
  background-color: #ffa555;
}

.pag__bullet.swiper-pagination-bullet-active {
  background-color: #ffa555;
  box-shadow: 0 2px 0 rgba(87, 50, 13, 0.34), 0 0 5px red, 0 0 10px red;
}

/* tabs */
.tabs {
}

.tabs__more {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs__btns {
  display: grid;
  grid-template-columns: auto auto auto auto;
  height: 45px;
  overflow: hidden;
  background-color: #000;
  background-image: linear-gradient(
    89deg,
    #282828 0%,
    #282828 15%,
    #1a1a1a 100%
  );
  box-shadow: 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  /* @media (width < 450px) {
          grid-template-columns: auto auto;
          height: 90px;
      } */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.tabs__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  color: darkgrey;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  box-sizing: border-box;
  padding-top: 2px;
  text-decoration: none;
  /* @media (width < 450px) {
          min-height: 40px
      } */
}

.tabs__btn:after {
  display: block;
  content: "";
  background: url(../images/tabs/btn-hover.png) no-repeat;
  width: 122px;
  height: 48px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 61px);
  opacity: 0;
  transition: 0.3s all;
}

.tabs__btn:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: -1;
  background-image: linear-gradient(
    89deg,
    #282828 0%,
    #282828 15%,
    #1a1a1a 100%
  );
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.tabs__btn:not(:nth-last-child(1)):before {
  border-right: 1px solid #000;
}

.tabs__btn:hover:after,
.tabs__btn_active:after {
  opacity: 1;
}

.tabs__btn_active {
  color: #fff;
}

.tabs__btn_active:before {
  background: #2b2b2b;
}

.tabs__content {
  background-color: #000;
  box-shadow: inset 0 0 161px 2px rgba(255, 255, 255, 0.11);
  height: 232px;
  overflow: hidden;
}

.tabs__tab {
  display: none;
  -webkit-animation: tab 1s ease forwards;
  animation: tab 1s ease forwards;
}

@-webkit-keyframes tab {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tab {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* scl */
.scl {
  display: flex;
  margin: 0 -7px;
  box-sizing: border-box;
  padding: 1px 0;
  justify-content: center;
}

.scl__link {
  width: calc(100% / 9 - 10px);
  display: block;
  margin: 5px;
  max-width: 108px;
  transition: 0.3s all;
}

.scl__link:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.scl__img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* stream */
[data-section="streams"] {
  flex-grow: 1;
  align-items: center;
  max-height: 300px;
  margin: 4px 0;
}

/* social */
[data-section="scl"] {
  margin: 5px 0;
}

.stream {
  height: 123px;
  margin: 0 auto;
  background-image: url(../images/streams/bg.webp);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: default;
  border: 0;
  box-sizing: border-box;
  text-align: center;
}

.stream_fullscreen {
  display: none;
}

.stream_fullscreen_visible {
  display: flex;
}

.stream iframe {
  width: 100%;
  height: 100%;
  border: unset;
}

.stream__link {
  text-decoration: none;
  color: rgb(196, 132, 49);
}

.stream__link:hover {
  color: rgb(230, 154, 56);
}

.stream__add {
  width: 47px;
  height: 32px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  transition: 0.3s all;
}

.stream__add:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.stream__add:after {
  display: block;
  content: "";
  background: url(../images/streams/add__plus.png) no-repeat;
  width: 65px;
  height: 83px;
  position: absolute;
  left: -9px;
  top: -22px;
  z-index: -1;
  pointer-events: none;
}

/* streams */
.streams {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.streams__stream {
  width: calc(100% / 5 - 10px);
  margin: 0 5px 5px 5px;
}

/* copyright */
.copyright {
}

.copyright__bnrs {
  display: flex;
}

.copyright__bnr {
  margin-right: 7px;
}

.copyright__txt {
  color: #545454;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  font-size: 8px;
  margin-top: 6px;
  line-height: 10px;
}

.copyright__site {
  color: white;
  font-size: 10px;
}

.copyright__policy {
  color: #b6b37c;
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s all;
}

.copyright__policy:hover {
  color: #dbd49a;
}

/* footer */
[data-section="footer"] {
  background-color: rgba(0, 0, 0, 0.88);
  position: relative;
  z-index: 4;
  margin-top: auto;
}

.footer {
  display: flex;
}

.footer__title {
  color: #fcd601;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  padding-left: 30px;
  margin-bottom: 10px;
}

.footer__title:before {
  display: block;
  content: "";
  background: url(../images/footer/ft.png) no-repeat;
  position: absolute;
  left: -8px;
  top: -5px;
  width: 23px;
  height: 24px;
}

.footer__nav {
  display: flex;
  flex-grow: 1;
  flex-basis: 760px;
}

.footer__f-nav {
  margin: 12px;
  flex-basis: 200px;
  /* flex-shrink: 0; */
  flex-grow: 1;
}

.footer__f-nav:nth-child(1) {
  padding-right: 40px;
}

.f-nav__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.f-nav__item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #b6b37c;
  font-family: "AlbertusExtraBold", "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 14px;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  padding-left: 26px;
  margin-bottom: 1px;
  transition: 0.3s all;
}

.f-nav__item:hover {
  color: #dbd49a;
}

.f-nav__item:before {
  display: block;
  content: "";
  background: url(../images/footer/fn.png) no-repeat;
  position: absolute;
  left: 0;
  top: 1px;
  width: 6px;
  height: 12px;
  opacity: 0.5;
  transition: 0.3s all;
}

.f-nav__item:hover:before {
  opacity: 1;
}

.footer__info {
  margin: 12px 0 12px 15px;
  width: 220px;
}

/* .footer__hint {
    position: absolute;
    left: calc(50% - 16px);
    top: -16px;
    z-index: 20;
    cursor: pointer;
}

.footer__arrow {
    cursor: pointer;
}

.body_footer_hide .footer__arrow {
    transform: rotateX(180deg);
}

.body_footer_hide .footer__dec {
    opacity: 0;
    visibility: hidden;
} */
.footer-switcher {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.footer-hide .footer-switcher__arrow {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

/* under */
.under-logo {
  max-width: 130px;
}

[data-section="under"] {
  background-color: rgba(0, 0, 0, 0.88);
  position: relative;
  z-index: 4;
}

[data-section="under"]:after {
  display: block;
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    #666 15%,
    #666 85%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.3;
}

.under {
  min-height: 55px;
  display: flex;
  align-items: center;
}

.under__ps {
  width: 100%;
  grid-gap: 5px;
}

/* modal */
.modal-active {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 99;
  display: flex;
  transition: 0.3s ease-in-out;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 20px;
  overflow-x: hidden;
}

.modal_none {
  display: none;
}

.modal_hide {
  opacity: 0;
}

.modal__close {
  background: url(../images/modal/close.png) no-repeat;
  width: 10px;
  height: 10px;
  -webkit-transform-origin: 5px 4px;
  transform-origin: 5px 4px;
  position: absolute;
  right: 4px;
  top: 11px;
  display: inline-block;
  z-index: 5;
  cursor: pointer;
  transition: 0.3s all;
  flex-shrink: 0;
  -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.98))
    drop-shadow(0 0 10px rgba(99, 99, 99, 0.27));
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.98))
    drop-shadow(0 0 10px rgba(99, 99, 99, 0.27));
}

.modal__inner {
  flex-basis: 420px;
  height: auto;
  margin: auto;
  background-color: transparent;
  transition: 0.5s all;
  -webkit-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  opacity: 1;
  position: relative;
  z-index: 1900;
  box-sizing: border-box;
  padding: 20px 0;
}

.modal_hide .modal__inner {
  -webkit-transform: scale(1.2) translateY(-100px);
  transform: scale(1.2) translateY(-100px);
  opacity: 0;
}

.modal__heading-decoration {
  background: url(../images/modal/modal__top.webp) no-repeat;
  width: 485px;
  height: 95px;
  position: absolute;
  right: -45px;
  top: -13px;
  pointer-events: none;
}

.modal__footer-decoration {
  background: url(../images/modal/modal__bottom.png) no-repeat;
  width: 442px;
  height: 56px;
  position: absolute;
  right: -9px;
  bottom: 4px;
  pointer-events: none;
}

.modal__heading-wide-decoration {
  background: url(../images/modal/wide/modal_top.png) no-repeat;
  width: 600px;
  height: 95px;
  position: absolute;
  right: -19px;
  top: -13px;
  pointer-events: none;
}

.modal__footer-wide-decoration {
  background: url(../images/modal/wide/modal_bottom.png) no-repeat;
  width: 600px;
  height: 56px;
  position: absolute;
  right: -31px;
  bottom: 4px;
  pointer-events: none;
}

.modal__head {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
}

.modal__title {
  color: #32325c;
  font-size: 18px;
  text-align: center;
}

.modal__title_warning {
  color: #fc7c56;
  font-size: 18px;
}

.modal__desc {
  color: #5b6787;
  font-size: 13px;
  text-align: center;
}

.modal__desc_m_t {
  margin-top: 6px;
}

.modal__content {
  background: linear-gradient(
      80deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.04) 70%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #201e1d url(../images/modal/bg-repeat.jpg) repeat;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 20px 30px;
  font-size: 13px;
  min-height: 100px;
  transition: all 0.3s ease;
  border-radius: 0 0 15px 15px;
}

.modal__content-wide {
  background: linear-gradient(
      80deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.04) 70%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #201e1d url(../images/modal/bg-repeat.jpg) repeat;
  width: 564px;
  margin: 0;
  box-sizing: border-box;
  padding: 20px 30px;
  font-size: 13px;
  min-height: 100px;
  transition: opacity 2s ease-in-out;
  border-radius: 0 0 15px 15px;
}

/* bhelp */
.bhelp_mt_files {
  margin-top: 15px;
}

.bhelp__item {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

.bhelp__ico {
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.bhelp__link {
  color: white;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  padding: 3px 0;
}

.bhelp__link:hover {
  color: rgb(253, 255, 144);
}

/* data-gw-tab="languages" */
[data-gw-tab="languages"] .select2 {
  width: 100% !important;
  height: 45px !important;
}

[data-gw-tab="languages"] .slanguage {
  width: 100% !important;
  height: 45px !important;
  line-height: 45px !important;
  background-color: white !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 10px;
  box-sizing: border-box !important;
  transition: none;
  color: #858585 !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  text-align: left !important;
  outline: none !important;
  min-width: 40px !important;
}

[data-gw-tab="languages"] .select2-selection__rendered {
  line-height: 45px !important;
  padding-left: 2px !important;
  color: rgb(133, 133, 133) !important;
}

[data-gw-tab="languages"] .select2-selection__arrow {
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.olanguage__item {
  display: flex;
  align-items: center;
  color: #858585 !important;
}

.olanguage__txt {
  padding-left: 10px;
  font-size: 13px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.olanguage .select2-results__option[aria-selected="true"] .olanguage__txt {
  color: #000 !important;
}

.olanguage .select2-results__option--highlighted[aria-selected] {
  background-color: rgb(208, 226, 255) !important;
  color: rgb(255, 255, 255) !important;
}

/* def */
.hidden {
  display: none;
}

.tt-uppercase {
  text-transform: uppercase;
}

.text-align-center {
  text-align: center;
}

[class^="gwi_"]:before,
[class*=" gwi_"]:before {
  margin: 0;
}

.pr {
  padding-right: 10px;
}

.pl {
  padding-left: 10px;
}

.pt {
  padding-top: 10px;
}

.pb {
  padding-bottom: 10px;
}

.mr {
  margin-right: 10px;
}

.ml {
  margin-left: 10px;
}

.mt {
  margin-top: 10px;
}

.mb {
  margin-bottom: 10px;
}

.m {
  margin: 10px;
}

.mra {
  margin-right: auto;
}

.mla {
  margin-left: auto;
}

.mta {
  margin-top: auto;
}

.ma {
  margin: auto;
}

.mba {
  margin-bottom: auto;
}

.mr0 {
  margin-right: 10px;
}

.ml0 {
  margin-left: 10px;
}

.mt0 {
  margin-top: 10px;
}

.mb0 {
  margin-bottom: 10px;
}

.m0 {
  margin: 0;
}

/* colors */
.color-light {
  color: #e8d1a6;
}

.color-red {
  color: rgb(255, 39, 39);
}

.color-yellow {
  color: #fcfc41;
}

.color-orange {
  color: #eecd56;
}

.color-pink {
  color: rgb(255, 117, 140);
}

.color-light-blue {
  color: rgb(87, 160, 206);
}

.color-blue {
  color: #398fcd;
}

.color-green {
  color: #97c647;
}

.color-grey {
  color: #c5c1c1;
}

.color-white {
  color: white;
}

.color-aqua {
  color: #56dcee;
}

/* other */
.sbttl {
  font-size: 18px;
  margin-top: 20px;
  display: inline-block;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fs095 {
  font-size: 0.95em;
}

.fs11 {
  font-size: 1.1em;
}

.fs12 {
  font-size: 1.2em;
}

.fs13 {
  font-size: 1.3em;
}

.fs14 {
  font-size: 1.4em;
}

.fs15 {
  font-size: 1.5em;
}

.fs2 {
  font-size: 2em;
}

.clear {
  clear: both;
}

@media (min-width: 500px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 600px) {
  .page {
    background: #110f12 url(../images/bg/bg.webp) no-repeat center top;
  }
}

@media (min-width: 1000px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1100px) and (min-height: 901px) {
  .footer-hide [data-section="servers"] {
    max-height: 164px;
  }
}

@media (min-width: 1101px) and (min-height: 1601px) {
  .page {
    min-height: 1600px;
    max-height: 1600px;
    height: 1600px;
  }
}

@media (min-width: 1350px) {
  .navigation__burger {
    display: none;
  }
}

@media (min-width: 1921px) {
  .page {
    background: #110f12 url(../images/bg/bg.webp) no-repeat center top;
    /*background: #110f12 url(../images/bg/bg-large.jpg) no-repeat center top;*/
  }
}

@media (min-width: 2600px) {
  .page {
    background: #110f12 url(../images/bg/bg.webp) no-repeat center top;
    /*background: #110f12 url(../images/bg/bg-big.jpg) no-repeat center top;*/
  }
}

@media (max-width: 1449px) {
  .logotype_small {
    display: none;
  }

  .banners {
    display: none;
  }

  .nav__link {
    margin: 0;
  }

  .nav__desc {
    font-size: 10px;
  }
}

@media (max-width: 1349px) {
  .bar {
    grid-template-columns: auto auto auto auto;
  }

  .gw-burger {
    display: block;
  }

  /* navigation */
  [data-container="navigation"] {
    max-width: none;
    width: 100%;
    padding: 0;
  }

  .navigation {
    display: block;
  }

  .navigation__nav {
    padding: 0;
  }

  .nav {
    position: fixed;
    left: 0;
    top: 61px;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    z-index: 10;
    background-color: rgba(28, 28, 25, 0.88);
    transition: 0.3s all;
    opacity: 0;
    visibility: hidden;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    overflow-y: auto;
  }

  .nav__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    transition: 0.3s all 0.3s;
    max-height: calc(100vh - 61px);
  }

  .nav__link {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 10px 10px 10px 40%;
    box-sizing: border-box;
    justify-content: flex-start;
    background-color: transparent;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.46);
    flex-grow: 1;
    min-height: 70px;
  }

  .nav__link:not(:nth-last-child(1)) {
    border-bottom: 2px solid rgba(255, 255, 255, 0.22);
  }

  .nav__link:hover {
    background-color: rgb(32, 31, 31);
  }

  .nav__ico {
    margin-right: 10px;
    width: 60px;
    height: 60px;
  }

  .nav__name {
    font-size: 20px;
    line-height: 1.2;
  }

  .nav__desc {
    font-size: 13px;
    line-height: 1.2;
  }

  .mob-nav-active {
    overflow: hidden;
  }

  .mob-nav-active .nav {
    opacity: 1;
    visibility: visible;
  }

  .mob-nav-active .nav {
    opacity: 1;
    visibility: visible;
  }

  .mob-nav-active .nav__list {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    max-height: calc(100vh - 61px);
  }
}

@media (max-width: 1099px) {
  .bar {
    right: 26px;
  }

  .logotype {
    left: 15px;
  }

  .servers {
    max-height: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer {
    display: block;
  }

  .footer__f-nav:nth-child(1) {
    padding-right: 0;
  }
}

@media (max-width: 1049px) {
  .item__date {
    width: 70px;
  }

  .mid {
    display: block;
  }

  .mid__slider,
  .mid__content {
    width: calc(100%);
    margin: 0;
  }

  .mid__slider {
    margin-bottom: 10px;
  }
}

@media (max-width: 949px) {
  [data-section="streams"] {
    max-height: none;
  }

  .streams__stream {
    width: calc(100% / 2 - 10px);
    margin: 5px;
  }
}

@media (max-width: 899px) {
  .nav__link {
    padding-left: 35%;
  }
}

/*@media (max-width: 720px) {*/
/*  .scl__link {*/
/*    width: calc(100% / 4 - 10px);*/
/*  }*/
/*}*/

@media (max-width: 799px) {
  .scl {
    flex-wrap: wrap;
  }

  /*.scl__link {*/
  /*  width: calc(100% / 4 - 10px);*/
  /*}*/

  [data-container="footer"] {
    display: flex;
    justify-content: center;
  }

  .footer__nav {
    display: block;
  }

  .footer__info {
    margin-bottom: 30px;
  }
}

@media (max-width: 649px) {
  .nav__link {
    padding-left: 30%;
  }
}

@media (max-width: 700px) {
  .priority_0 {
    order: 0 !important;
    z-index: 1 !important;
  }
  .priority_1 {
    order: 1 !important;
  }
  .priority_2 {
    order: 2 !important;
  }
  .priority_3 {
    order: 3 !important;
  }

  .server {
    margin: 20px 0;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  [data-section="servers"] {
    max-height: none;
    display: grid;
  }

  .servers {
    max-height: none;
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 80px;
    grid-row-gap: 20px;
    justify-content: center;
  }

  .servers__btn-box {
    order: -1;
    z-index: 2;
    margin: 20px 0;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

@media (max-width: 499px) {
  .server {
    display: flex;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    order: 1;
    z-index: 0;
  }

  .servers {
    display: flex;
    flex-direction: column;
  }

  .servers__btn-box {
    order: -1 !important;
    z-index: 1 !important;
    margin: 20px 0;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  .logotype {
    max-width: 135px;
  }

  .nav__link {
    padding-left: 10%;
  }

  .mob-nav-active .nav__list {
    /*-webkit-transform: translateY(0);*/
    /*transform: translateY(0);*/
    /*opacity: 1;*/
    max-height: calc(100vh - 61px);
  }

  /*.lang__hint {*/
  /*  display: none;*/
  /*}*/

  .snews__title {
    font-size: 30px;
  }

  .snews__sub-title {
    font-size: 18px;
  }

  .snews__counter {
    width: 30px;
    font-size: 12px;
  }

  .snews__arrows {
    padding-left: 3px;
    padding-right: 3px;
  }

  .scl__link {
    width: calc(100% / 3 - 10px);
  }

  .streams__stream {
    width: calc(100%);
    margin: 5px 0;
  }
}

/*@media (max-width: 460px) {*/
/*  .preload {*/
/*    display: none;*/
/*  }*/
/*}*/

@media (max-width: 449px) {
  .item__content {
    padding-right: 10px;
  }

  /*.item__btns {*/
  /*  display: none;*/
  /*}*/
}

@media all and (min-width: 499px) and (max-width: 950px) {
  .streams__stream.stream:nth-child(4) {
    display: none;
  }
}

.response {
  display: block;
}

.error {
  padding: 10px;
  margin: 0 0 10px;
  background: #ff4436;
  color: #fff;
  font-weight: bold;
}

.success {
  padding: 10px;
  margin: 0 0 10px;
  background: #04aa6d;
  color: #fff;
  font-weight: bold;
}

.info {
  padding: 10px;
  margin: 0 0 10px;
  background: #2196f3;
  color: #fff;
  font-weight: bold;
}

.warning {
  padding: 10px;
  margin: 0 0 10px;
  background: #ff9800;
  color: #fff;
  font-weight: bold;
}

.fadein {
  animation: fadein 1s ease-in;
}

.fadeout {
  animation: fadeout 1s ease-out;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(1.15);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.75);
  }
}

/* STATISTICS TABLE VIEW */
.disabled {
  opacity: 0.3;
}

.bold {
  font-weight: bold;
}

._pvp {
  color: white;
}

._pk {
  color: red;
}

table#stats {
  width: 100%;
  max-width: 360px !important;
  border-collapse: collapse;
  border: 1px solid #111;
  font-family: Tahoma, Arial, Verdana, sans-serif;
  font-size: 12px;
}

#stats td,
#stats th {
  border: 1px solid #444;
  padding: 2px 5px;
}

#stats tr:nth-child(odd) {
  background-color: #222;
}

#stats tr:nth-child(even) {
  background-color: #333;
}

#stats tr:hover {
  background-color: #111;
}

#stats th {
  padding: 4px;
  background-color: #000;
  color: white;
}

.text-center {
  text-align: center;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.hasTooltip span {
  display: none;
  color: #fff;
  text-decoration: none;
  padding: 3px;
}

.hasTooltip:hover span {
  display: block;
  position: absolute;
  background-color: #000;
  border: 1px solid #ccc;
  margin: 2px 10px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  /*border-bottom: 1px dotted white; !* If you want dots under the hover able text *!*/
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: #000;
  color: #fff;
  text-align: left;
  padding: 5px 5px;
  border-radius: 0;
  border: 1px solid #fff;

  /* Position the tooltip text - see examples below! */
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  position: absolute;
  z-index: 1;
}

.male {
  color: deepskyblue;
}

.female {
  color: deeppink;
}

.ico_status_on {
  background: url("~@/assets/images/icons/ico_status_salad.png") no-repeat
    center;
}

.ico_status_off {
  background: url("~@/assets/images/icons/ico_status_red.png") no-repeat center;
}

.fix-wrap-char-title {
  max-width: 111px;
  /*word-wrap: break-word;*/
  white-space: pre-wrap;
  overflow: hidden;
}

.fix-wrap-clan-title {
  max-width: 76px;
  /*word-wrap: break-word;*/
  white-space: pre-wrap;
  overflow: hidden;
}

.priority_0 {
  order: 0 !important;
  z-index: 1;
}

.priority_1 {
  order: 1 !important;
}

.priority_2 {
  order: 2 !important;
}

.priority_3 {
  order: 3 !important;
}

.priority_4 {
  order: 4 !important;
}

.priority_5 {
  order: 5 !important;
}

/* CSS Loader */
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
  margin: 0 auto 10px;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #ff3d00;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
